/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'

const getUserAccessToken = async () => {
  try {
    const session = await Auth.currentSession()
    const accessToken = session.getAccessToken().getJwtToken()
    return accessToken
  } catch (error) {
    // token expired, force user to login again
    Auth.federatedSignIn({ provider: 'kaercher-login' } as any)
    console.error('Error getting access token:', error)
    return null
  }
}

export const signOut = async () => {
  const endSessionUrl = `${process.env.REACT_APP_ORIGINAL_AUTH_URL}/connect/endsession`
  const frame = window.document.createElement('iframe')
  frame.src = endSessionUrl
  frame.style.display = 'none'
  window.document.body.appendChild(frame)
  frame.onload = () => {
    window.document.body.removeChild(frame)
    setTimeout(() => {
      Auth.signOut()
    }, 300);
  }
}

export { getUserAccessToken }
