import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useMyUserProfileLazyQuery } from 'service/generated/graphql'
import { setUser } from 'state/user/slice'

const AMPLIFY_AUTH_REDIRECT = '/authenticate'

const AuthenticationPage = () => {
  const dispatch = useDispatch()
  const [fetch] = useMyUserProfileLazyQuery()

  const pathname = location.pathname

  const [error, setError] = useState<string | null>('')

  const onSignIn = () => {
    Auth.federatedSignIn({ provider: 'kaercher-login' } as any)
  }

  useEffect(() => {
    const getUser = async () => {
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: true })
        const { data } = await fetch()

        dispatch(
          setUser({
            ...data?.getUserMetadata,
            ...data?.getUserProfile,
          }),
        )

      } catch (error) {
        // redirect from federated sign in
        if (pathname === AMPLIFY_AUTH_REDIRECT) {
          setError('Error message: ' + error)
        } else {
          onSignIn()
        }
      }
    }
    getUser()
  }, [])

  return (
    <div className='text-center' style={{ padding: 20 }}>
      {!error ? null : (
        <div>
          <h4>Authentication Error</h4>
          <p>{error}</p>
          <Button onClick={onSignIn}>Sign In</Button>
        </div>
      )}
    </div>
  )
}

export default AuthenticationPage
